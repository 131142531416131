/**
 * = Custom forms
 */

.custom-control-label {

	// Background-color and (when enabled) gradient
	&:before {
		box-shadow: $custom-control-box-shadow;

		@if $enable-transitions {
			transition: $input-transition;
		}
	}

	span {
		position: relative;
		top     : 2px;
	}
}

.custom-control-label {
	margin-bottom: 0;
}

.custom-control-input {
	&:active~.custom-control-label::before {
		border-color: $custom-control-indicator-active-border-color;
	}
}