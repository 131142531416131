/**
 * = Dropdowns
 */

.dropdown-menu {
    min-width: 12rem;

    .dropdown-header,
    .dropdown-item {
        padding  : .5rem 1rem;
        font-size: $dropdown-font-size;
    }

    .dropdown-header {
        color      : $dropdown-header-color;
        font-weight: $dropdown-header-font-weight;
    }

    .dropdown-item {
        color      : $dropdown-color;
        transition : $transition-base;
        font-weight: $dropdown-item-font-weight;

        &a:hover {
            color: $dropdown-item-hover-color;
        }
    }

    .show & {
        animation: show-dropdown .2s ease forwards;
    }

}

// this helps when not only the button should toggle a dropdown
[data-toggle]:hover {
    cursor: pointer;
}

// remove the caret from Bootstrap by default
.dropdown-toggle {

    &:after,
    .dropend &:after,
    .dropstart &:before,
    .dropup &:after {
        display: none;
    }
}

// Dropown sizes
.dropdown-menu-sm {
    min-width: 100px;
    border   : $border-radius-lg;
}

.dropdown-menu-md {
    min-width: 180px;
    border   : $border-radius-lg;
}

.dropdown-menu-lg {
    min-width    : 260px;
    border-radius: $border-radius-lg;
}

.dropdown-menu-xl {
    min-width    : 450px;
    border-radius: $border-radius-lg;
}

.sr-only {
    background-color: $primary;
    color           : $white;
}