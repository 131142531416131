/**
 * = Close
 */

.close {
    @if $enable-transitions {
        transition: $transition-base;
    }

    &>span:not(.sr-only) {
        display         : block;
        height          : 1.25rem;
        width           : 1.25rem;
        background-color: $close-bg;
        color           : $close-color;
        line-height     : 22px;
        border-radius   : 50%;
        font-size       : 1.25rem;

        @if $enable-transitions {
            transition: $transition-base;
        }
    }

    &:hover,
    &:focus {
        background-color: $close-hover-bg;
        color           : $close-hover-color;
        outline         : none;

        span:not(.sr-only) {
            background-color: $close-hover-bg;
        }
    }
}